.react-date-picker {
    width: inherit;
}

.react-date-picker__wrapper {
    border: thin solid transparent;
}

.react-date-picker__inputGroup__input:invalid {
    background: rgba(126, 160, 77, 0.1);
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: $verde;
}

.react-calendar__tile--active {
    background: $verde;
    color: white;
}
