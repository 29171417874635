.icon_svg {
    width: 28px;
    height: 23px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 5px;
    padding-left: 5px;
}

.table tbody tr:hover > td {
    filter: invert(7%) sepia(50%) saturate(8%) hue-rotate(100deg)
        brightness(1000%) contrast(100%);
}
