@import '../configs/color.css';
@import '../configs/breakpoints.css';

/* Bootstrap table */
.table {
    background-color: white !important;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #f1f1f1 !important;
}

.table-hover tbody tr:hover {
    color: #000000;
    background-color: $gris !important;
}

.react-bs-container-body {
    overflow-x: hidden;
    /* overflow-x: scroll !important; */
    /* @media (min-width: $sm) { */
    /* } */
}

.table-wrap {
    td {
        white-space: normal !important;
    }
}

.react-bs-table {
    border: none !important;

    thead {
        padding: 0.2rem !important;
        color: white;
        background-color: $negro32 !important;
    }

    th {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        text-transform: uppercase;
        font-weight: bold !important;
        font-size: 16px;

        @media (max-width: $md) {
            font-size: 13px;
        }
    }

    th:first-child {
        border-top-left-radius: 8px !important;
    }

    td:first-child {
        border-top-left-radius: 25px !important;
        border-bottom-left-radius: 25px !important;
    }

    td:last-child {
        border-top-right-radius: 25px !important;
        border-bottom-right-radius: 25px !important;
    }

    th:last-child {
        border-top-right-radius: 8px !important;
    }

    th,
    td,
    tr {
        border: none !important;
    }

    td {
        font-weight: 500 !important;
        color: $negro32 !important;
        /* border-radius: 25px !important; */
        font-size: 16px;

        @media (max-width: $md) {
            font-size: 13px;
        }
    }

    tbody {
        /* tr:nth-child(2n + 1) {
            background-color: white !important;
        } */

        tr {
            border-radius: 25px !important;
        }

        /* tr:hover {
            background-color: $gris !important;
        }

        tr:nth-child(2n) {
            background-color: $gris_table;
        } */
    }
}

/* Paginacion de la tabla */
.page-item.disabled .page-link {
    color: $gris;
}

.page-item.active .page-link {
    color: #fff;
    background-color: $gris !important;
    border-color: $gris !important;
}

.pagination {
    padding-left: 0;
    list-style: none;
    border-radius: 15px !important;
    font-family: 'Raleway', sans-serif !important;
    font-size: 14px !important;

    @media (max-width: $md) {
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 768px) {
    .react-bs-container-body {
        overflow-x: scroll !important;
    }

    .react-bs-table-container .table {
        min-width: 900px !important;
        /* max-width: 2000px !important; */
    }
}

.react-bs-container-header {
    color: #fff !important;
    background: $negro32;
    padding: 1px !important;
    border-radius: 1rem 1rem;
}

.react-bs-container-header th {
    color: white;
    text-align: center;
}
