@import './breakpoints.css';
@import './color.css';

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $negro;
    font-family: 'Roboto', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $negro;
    font-family: 'Roboto', sans-serif !important;
}

.h1,
h1 {
    font-size: 3.052rem;
    line-height: 3rem;
    font-family: 'Roboto', sans-serif !important;

    @media (max-width: $md) {
        font-size: 2.5rem;
    }
}

.h1,
.h2,
h1,
h2 {
    letter-spacing: -0.0625rem;
}

.h2,
h2 {
    font-size: 2.441rem;

    @media (max-width: $md) {
        font-size: 2rem;
    }
}

.h2,
.h3,
h2,
h3 {
    line-height: 2.25rem;
}

.h3,
h3 {
    font-size: 1.953rem;

    @media (max-width: $md) {
        font-size: 1.65rem;
    }
}

.h4,
h4 {
    font-size: 1.563rem;
    line-height: 2rem;

    @media (max-width: $md) {
        font-size: 1.5rem;
    }
}

.h5,
h5 {
    font-size: 1.25rem;

    @media (max-width: $md) {
        font-size: 1.25rem;
    }
}

.h5,
.h6,
h5,
h6 {
    line-height: 1.5rem;
}

.h6,
h6 {
    font-size: 1rem;

    @media (max-width: $md) {
        font-size: 0.9rem;
    }
}

label,
.label {
    font-size: 16px;
    font-weight: 500 !important;
    padding-left: 0.25rem !important;
    color: $negro32 !important;

    @media (max-width: $md) {
        font-size: 13px;
    }
}

span {
    font-size: 16px;
    color: $negro32;

    @media (max-width: $md) {
        font-size: 13px;
    }
}

.text-11 {
    font-size: 11px !important;

    @media (max-width: $md) {
        font-size: 9px !important;
    }
}

.text-12 {
    font-size: 12px !important;

    @media (max-width: $md) {
        font-size: 10px !important;
    }
}

.text-13 {
    font-size: 13px !important;

    @media (max-width: $md) {
        font-size: 11px !important;
    }
}

.text-14 {
    font-size: 14px !important;

    @media (max-width: $md) {
        font-size: 12px !important;
    }
}

.text-16 {
    font-size: 16px !important;

    @media (max-width: $md) {
        font-size: 13px !important;
    }
}

.text-18 {
    font-size: 18px !important;

    @media (max-width: $md) {
        font-size: 14px !important;
    }
}

.text-20 {
    font-size: 20px !important;

    @media (max-width: $md) {
        font-size: 16px !important;
    }
}

.text-25 {
    font-size: 25px !important;

    @media (max-width: $md) {
        font-size: 20px !important;
    }
}

.text-30 {
    font-size: 30px !important;

    @media (max-width: $md) {
        font-size: 24px !important;
    }
}

.text-35 {
    font-size: 35px !important;

    @media (max-width: $md) {
        font-size: 29px !important;
    }
}

.text-40 {
    font-size: 40px !important;

    @media (max-width: $md) {
        font-size: 32px !important;
    }
}

.text-45 {
    font-size: 45px !important;

    @media (max-width: $md) {
        font-size: 37px !important;
    }
}

.text-50 {
    font-size: 50px !important;

    @media (max-width: $md) {
        font-size: 40px !important;
    }
}

.tachado {
    text-decoration: line-through !important;
}

.tachado-touch {
    text-decoration: line-through !important;
    cursor: pointer !important;
}

.bold,
.text-bold {
    font-weight: 700 !important;
}

.ligh .text-ligh {
    font-weight: 300 !important;
}

.regular,
.text-regular {
    font-weight: 400 !important;
}

.medium,
.text-medium {
    font-weight: 500 !important;
}

.puntero {
    text-decoration: pointer !important;
}

a {
    text-decoration: none !important;
}
