@import '../../../../../style/configs/breakpoints.css';
@import '../../../../../style/configs/color.css';

.login-overlay .panel-body {
    min-height: 244px;
}

.has-feedback {
    position: relative;
}

.form-control-feedback {
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}

.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-login {
    border: 0;
    border-radius: 45px;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-right: 15vw;
    padding: 30px 20px;
    padding: 100px 50px 75px 50px !important;
    width: 400px !important;
    background-color: white;
    overflow: hidden;
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    @media (max-width: $md) {
        animation: slideInRight 1.5s;
        margin-right: 0;
        padding: 75px 35px 54px 35px !important;
    }

    @media (max-width: $sm) {
        width: auto !important;
        margin-right: 25px;
        margin-left: 25px;
        padding: 50px 25px 37px 25px !important;
    }
}

.bienvenida {
    color: white;
    font-weight: bold;
}

.buttons-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.blue-gradient-bg {
    min-height: 100vh;
    background-image: url(../../../../../assets/img/background_image.png),
        linear-gradient(
            to right,
            #fafafa,
            #f1f1f1,
            #f9f9f9,
            #e4e4e4,
            #e9e9e9,
            #e5e5e5,
            #dedede,
            #cecbcb,
            #c2c0c2
        );
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    @media screen and (max-width: 576px) and (orientation: landscape) {
        height: auto !important;
        padding: 30px;
    }
}

.card-registro {
    border: 0;
    border-radius: 45px;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 62.9%;
    padding: 40px 5% 54px 5% !important;
    background-color: white;

    @media (max-width: $md) {
        margin-right: 0;
        padding: 40px 3% 54px 3% !important;
    }

    @media (max-width: $sm) {
        width: auto !important;
        margin-right: 25px;
        margin-left: 25px;
        padding: 50px 25px 37px 25px !important;
    }
}

.logo-registro {
    height: 22px;

    @media (min-width: $sm) {
        height: 26px;
    }
}

.circulo {
    width: 23px;
    height: 23px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid black;
    background: white;
    z-index: 100;
    color: black;
    font-size: 15px !important;
}

.circulo-activo {
    background-color: #cd0a0a;
    z-index: 100;
    color: white;
    font-size: 15px !important;
}

.link {
    color: #cd4545;
}

.link:hover {
    color: #cd0a0a;
}

@-webkit-keyframes slideInRight {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes slideInRight {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
