@import '../configs/color.css';

input {
    border-radius: 15px !important;
}

.form-control:focus,
.form-control:focus:hover {
    background-color: white !important;
}

.form-control,
.form-control {
    background-color: #f1f1f1 !important;
}

.css-1pcexqc-container .css-bg1rzq-control {
    border-radius: 15px !important;
    background-color: transparent !important;
    height: 2.68rem !important;
}

.filter {
    background-color: #ffffff;
    border-radius: 15px;
}

.select {
    background-color: #f1f1f1;
    border-radius: 15px;
}

.css-1pcexqc-container .css-kj6f9i-menu {
    border-radius: 15px !important;
}

.css-1pcexqc-container .css-1szy77t-control {
    border-radius: 15px !important;
    height: 2.68rem !important;
}

.form-control {
    display: block;
    width: 100%;
    height: 2rem;
    padding: 0.375rem 0.75rem;
    font-size: 18px;
    font-weight: 700 !important;
    line-height: 1.5;
    color: $negro !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $negro;
    border-radius: 5px;
    -webkit-transition: border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-inline {
    height: 2rem;
    padding: 0.375rem 0.75rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: $negro !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $negro;
    border-radius: 5px;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: initial;
    border: 0;
}

.form-control:focus {
    border-color: $verde !important;
    outline: 0;
    box-shadow: unset !important;
}

.form-control:hover {
    border-color: $verde !important;
}

.form-control:focus,
.form-control:focus:hover {
    border-color: $verde !important;
    outline: 0;
    box-shadow: unset !important;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e2e2e2 !important;
    opacity: 2 !important;
}

.form-control:disabled:hover,
.form-control[readonly]:hover {
    border: 1px solid $negro !important;
}

/*CAMPO INVALIDO*/
.invalid-feedback {
    color: $rojo;
}

.is-invalid {
    border-color: $rojo !important;
}

/* SELECTS */
.css-bg1rzq-control,
.css-1ajl57w-control {
    border-radius: 5px !important;
    border: 1px solid #e1e1e1 !important;
    font-size: 18px !important;
    height: 40px !important;
    min-height: unset !important;
    font-weight: 700 !important;

    .css-151xaom-placeholder {
        font-size: 18px;
    }
}

.css-es53b3-multiValue {
    border-radius: 0.5rem !important;
}

.css-3pbdf8-control {
    border-radius: 5px !important;
    border: 1px solid $negro !important;
    font-size: 13px !important;
}

.css-o5t5i1-control {
    border-radius: 5px !important;
    border-color: $verde !important;
    box-shadow: 0 0 0 1px $verde !important;
}

/*Icono de expandible*/
.css-16pqwjk-indicatorContainer {
    color: $negro !important;
    padding: 0 8px 0 2px !important;
}

.css-1thkkgx-indicatorContainer {
    color: $negro !important;
    padding: 0 8px 0 2px !important;
}

/*Icono separador*/
.css-bgvzuu-indicatorSeparator {
    background-color: white !important;
}

/*Selecto cuando esta activo*/
.css-1szy77t-control,
.css-juf2xh-control {
    border-radius: 5px !important;
    font-size: 18px !important;
    height: 40px !important;
    min-height: unset !important;
    border-color: $verde !important;
    box-shadow: 0 0 0 1px $verde !important;
}

/*Selecto cuando esta desactivado*/
.css-14ojxsb-control {
    border-radius: 5px !important;
    border: 1px solid #ced4da !important;
    font-size: 13px !important;
    height: 40px !important;
    min-height: unset !important;
    background-color: #e9ecef !important;
}

/*El texto de adentro*/
.css-dvua67-singleValue {
    color: $negro !important;
    font-size: 18px;

    @media (max-width: $md) {
        font-size: 14px;
    }
}

.css-ue83k8-singleValue {
    color: $negro !important;
    font-size: 14px;

    @media (max-width: $md) {
        font-size: 12px;
    }
}

/*Items del select*/
.css-kj6f9i-menu {
    z-index: 8000 !important;
    color: $negro !important;
    border: 1px $verde solid !important;

    font-size: 18px;

    @media (max-width: $md) {
        font-size: 14px;
    }
}

/* Hover en el menu de seleccion */
.css-dpec0i-option {
    background-color: $gris !important;
    border-radius: 4px !important;
    color: $negro !important;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
}

/* Item del menu */
.css-fk865s-option {
    background-color: transparent;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
}

/* Item seleccionado del menu */
.css-xo7z33-option {
    background-color: $verde !important;
    border-radius: 4px !important;
    color: hsl(0, 0%, 100%);
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
}

.invalid-force {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $rojo;
}

/* Checkbox and radio button */
.c-checkbox,
.c-radio {
    margin-right: 4px;
}

.c-checkbox *,
.c-radio * {
    cursor: pointer;
}

.c-checkbox input,
.c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important;
}

.c-checkbox span,
.c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: sub;
    width: 18px;
    height: 18px;
    border: 1px solid $negro;
    text-align: center;
}

.c-checkbox span:before,
.c-radio span:before {
    margin-left: 1px;
}

.c-radio:hover span {
    border: 2px solid $verde;
}

.c-checkbox:hover span {
    border: 2px solid $verde;
}

.form-inline .c-checkbox span,
.form-inline .c-radio span {
    margin-left: 0;
}

.c-checkbox.c-checkbox-rounded span,
.c-checkbox.c-radio-rounded span,
.c-radio.c-checkbox-rounded span,
.c-radio.c-radio-rounded span {
    border-radius: 500px;
}

/* override for radio */
.c-radio span {
    border-radius: 500px;
}

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 13px;
    line-height: 18px;
    vertical-align: middle;
}

/* Checked state */
.c-checkbox,
.c-radio {
    /* override for radio */
    /* Disable state */
    /* override for radio */
}

.c-checkbox input[type='checkbox']:checked + span:before,
.c-checkbox input[type='radio']:checked + span:before,
.c-radio input[type='checkbox']:checked + span:before,
.c-radio input[type='radio']:checked + span:before {
    color: white;
    opacity: 1;
    transition: color 0.3s ease-out;
}

.lectura input[type='checkbox']:checked + span:before,
.lectura input[type='radio']:checked + span:before,
.lectura input[type='checkbox']:checked + span:before,
.lectura input[type='radio']:checked + span:before {
    color: $verde;
    opacity: 1;
    transition: color 0.3s ease-out;
    cursor: initial;
}

.c-checkbox input[type='checkbox']:checked + span,
.c-checkbox input[type='radio']:checked + span,
.c-radio input[type='checkbox']:checked + span,
.c-radio input[type='radio']:checked + span {
    border-color: $verde;
    background-color: $verde;
}

.lectura input[type='checkbox']:checked + span,
.lectura input[type='radio']:checked + span,
.lectura input[type='checkbox']:checked + span,
.lectura input[type='radio']:checked + span {
    border-color: #fff !important;
    background-color: #eff3f6 !important;
    cursor: initial;
}

.lectura {
    cursor: initial !important;
}

.lec {
    border: 0px solid #eff3f6 !important;
}

.c-checkbox input[type='radio']:checked + span,
.c-radio input[type='radio']:checked + span {
    background-color: #fff;
}

.c-checkbox input[type='radio']:checked + span:before,
.c-radio input[type='radio']:checked + span:before {
    color: $verde;
}

.c-checkbox input[type='checkbox']:disabled + span,
.c-checkbox input[type='radio']:disabled + span,
.c-radio input[type='checkbox']:disabled + span,
.c-radio input[type='radio']:disabled + span {
    border-color: $negro !important;
    background-color: $gris !important;
}

.c-checkbox input[type='radio']:disabled + span,
.c-radio input[type='radio']:disabled + span {
    background-color: #fff !important;
}

.c-checkbox input[type='radio']:disabled + span:before,
.c-radio input[type='radio']:disabled + span:before {
    color: #ddd;
}

.c-radio.c-radio-nofont {
    /* override for radio */
    /* Disable state */
    /* override for radio */
}

.c-radio.c-radio-nofont span:before {
    content: '';
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 500px;
}

.c-radio.c-radio-nofont input[type='radio']:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color 0.3s ease-out;
}

.c-radio.c-radio-nofont input[type='radio']:checked + span {
    /* border-color: $azul; */
    border: 2px solid $verde;
    background-color: $verde;
}

.c-radio.c-radio-nofont input[type='radio']:checked + span {
    background-color: #fff;
}

.c-radio.c-radio-nofont input[type='radio']:checked + span:before {
    background-color: $verde;
}

.c-radio.c-radio-nofont input[type='radio']:disabled + span {
    border-color: $gris !important;
    background-color: $gris !important;
}

.c-radio.c-radio-nofont input[type='radio']:disabled + span {
    background-color: $gris !important;
    border-color: $negro !important;
}

.c-radio.c-radio-nofont input[type='radio']:disabled + span:before {
    background-color: $negro;
}

/* Switch */
.react-switch-bg {
    border-radius: 6px !important;
    /* border: solid 1px $negro !important; */
    background: $negro;
    color: $negro !important;
}

.react-switch-handle[aria-checked='false'] {
    height: 13px !important;
    width: 13px !important;
    top: 2px !important;
    box-shadow: none !important;
    background: $negro !important;
    transform: translateX(3px) !important;
}

.react-switch-handle {
    height: 13px !important;
    width: 13px !important;
    top: 2px !important;
    box-shadow: none !important;
    background: white !important;
    transform: translateX(19px);
}

.day-container {
    padding-right: 0 !important;
    padding-left: 0 !important;

    .col-2 {
        justify-content: start !important;
    }

    @media (max-width: $sm) {
        padding-right: 0 !important;
        padding-left: 0 !important;

        .col-md-12 {
            padding-right: 0 !important;
            padding-left: 0 !important;

            .col-10 {
                padding-right: 0 !important;
                padding-left: 0 !important;
            }

            .col-2 {
                padding-right: 0 !important;
                padding-left: 0 !important;
                justify-content: center !important;
            }
        }
    }
}

.search-input {
    background-size: 20px 18px;
    background-position: 98% center;
    background-repeat: no-repeat;
}

/*BUSCADOR*/

.container-search {
    /*position: relative;*/
    /*  @media (max-width: 425px) {*/
    /*  margin-top: 5px;*/
    /*}*/
    position: relative;
    width: 220px;
}

.input--search {
    width: 200px;
    max-width: 0;
    padding: 10px;
    transition: all 0.5s ease;
    position: absolute;
    right: 5px;
    box-sizing: border-box;
    opacity: 0;
    border-top: 1px solid $gris;
    border-left: 1px solid $gris;
    border-bottom: 1px solid $gris;
    font-weight: bold;
}

.input--search.expanded {
    max-width: 500px;
    opacity: 1;
}

.search_active {
    background-color: white !important;
    color: $negro !important;
}

.icon-search {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 0;
    border-radius: 10px;
    background: white;
    border-top: 1px solid $gris;
    border-left: 1px solid $gris;
    border-bottom: 1px solid $gris;
    border-right: 1px solid $gris;
    cursor: pointer;
}
