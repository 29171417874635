.ant-tabs {
    &-nav {
        display: flex;

        .ant-tabs-tab {
            flex-grow: 1;
            margin-right: 0px;
            width: 100%;
            text-align: center;
        }
    }
}

.overflow-tabs>div>div .rc-tabs-tabpane {
    overflow: hidden;

}
