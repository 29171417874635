@import '../configs/color.css';

.rc-tabs-tab {
    color: $negro !important;
    border: 1px solid $gris !important;
    font-weight: 700 !important;
    font-size: 16px;

    @media (max-width: $md) {
        font-size: 13px;
    }
}

.rc-tabs-tab-active {
    font-weight: bold;
    color: white !important;
    background-color: $negro !important;
    border: 1px solid $gris !important;
}

.rc-tabs-ink-bar {
    background-color: $negro;
}

.rc-tabs-tabpane-active {
    min-height: 55vh !important;
}

.rc-tabs-tab-active.rc-tabs-tab {
    color: white !important;
}

.rc-tabs-tab-active.rc-tabs-tab:hover {
    color: white;
}

.rc-tabs-tab:hover {
    color: white !important;
    background-color: $negro !important;
}

.rc-tabs-top,
.rc-tabs-bar {
    border-bottom: none !important;
}

.rc-tabs-nav-wrap {
    background-color: white !important;
}

.rc-tabs-nav.rc-tabs-nav-animated {
    background: $negro !important;
    border-radius: 30px;
}

.rc-tabs-nav.rc-tabs-nav-animated>div>div:last-child {
    border-radius: 0px 15px 15px 0px;
}

.rc-tabs-nav.rc-tabs-nav-animated>div>div:first-child {
    border-radius: 15px 0px 0px 15px;
}

.rc-tabs-ink-bar.rc-tabs-ink-bar-animated {
    display: none !important;
}

.rc-tabs-tabpane {
    overflow-x: hidden !important;
}
