@import './configs/texto.css';
@import './configs/color.css';
@import './components/buttons.css';
@import './components/table.css';
@import './configs/breakpoints.css';
@import './components/cards.css';
@import './components/tabs.css';
@import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
@import '../../node_modules/react-day-picker/lib/style.css';
@import 'bootstrap_reset.css';
@import 'components/form.css';
@import './components/navbar.css';
@import './components/sidebar.css';
@import './components/pagination.css';
@import './components/date-picker.css';
@import './components/impresion.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.container {
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
    font-size: 16px;
    margin-left: 60px;
    margin-right: 60px;
}

@media only screen and (max-width: 768px) {
    .container {
        margin-left: 0;
        margin-right: 0;
    }
}

.content-wrapper {
    position: absolute;
    top: 75px;
    width: 100%;
    background: #f5f6f8;
}

body {
    background-color: #f2f2f2;
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px;
    font-weight: 500;

    @media (max-width: $md) {
        font-size: 13px;
    }
}

/*Style preloader*/
.loader-container {
    min-height: 140px;
}

.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 60px;
    height: 60px;
    left: 50%;
    background-color: #e0e0e0;
    position: absolute;
    margin: 1rem 1rem 1rem -30px;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
}

.small-loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    position: absolute;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
    margin: 1rem 1rem 1rem -30px;
    left: 40%;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

#main-logo {
    max-width: 156px;
}

@import 'all.css';
@import 'all2.css';

.error-template {
    padding: 40px 15px;
    text-align: center;
}

.error-actions {
    margin-top: 15px;
    margin-bottom: 15px;
}

.error-actions .btn {
    margin-right: 10px;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

/*Sobre-escribiendo z-index para el swal*/
.swal2-container {
    z-index: 999999;
}

.swal2-content {
    color: #000;
    font-size: 18px;
    font-weight: 700;
}

/* boton contraseña(svg ojo) para poder ver la contraseña o ocultarlo */
.btn-password {
    background-color: transparent !important;
    border: none !important;
    position: absolute;
    /* left: 90%; */
    right: 5%;
    bottom: 0%;
}

.btn-password:focus {
    outline: none !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    position: relative;
    padding: 5px 4px;
    padding-left: 40px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.carousel .slider-wrapper {
    border-radius: 15px;
}

/* Collapse para la contrasena */
.collapse-contrasena>.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    position: relative;
    padding: 7px 16px;
    /* padding-left: 20px; */
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 15px !important;
}

.collapse-contrasena>.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    outline: none !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    left: 15px;
}

.collapse-contrasena>.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    border: 2px solid $rojo39 !important;
    position: relative !important;
    color: $rojo39;

    .anticon {
        vertical-align: 0px;
    }
}

/* .collapse-contrasena>.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    top: 15px;
} */

/* boton contraseña(svg ojo) para poder ver la contraseña o ocultarlo */
.btn-password {
    background-color: transparent !important;
    border: none !important;
    position: absolute;
    /* left: 90%; */
    right: 5%;
    bottom: 0%;
}

.btn-password:focus {
    outline: none !important;
}

/*Importando estilos para rc-tabs*/
@import '../../node_modules/rc-tabs/assets/index.css';
@import './rc-tabs.css';
